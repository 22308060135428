import { createRouter, createWebHistory } from "vue-router";
import LandingPage from "../views/LandingPage.vue";
/* import CookiesPage from "../views/CookiesPage.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TosPage from "../views/TosPage.vue";
import ConstructionPage from "../views/ConstructionPage.vue"; */
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    name: "landingpage",
    component: LandingPage,
    meta: {
      title: "Leija - Simple and easy going",
    },
  },
  /* {
    path: "/cookies",
    name: "CookiesPage",
    component: CookiesPage,
    meta: {
      title: "Cookies - Leija",
    },
  },
  {
    path: "/anvandarvillkor",
    name: "anvandarvillkor",
    component: TosPage,
    meta: {
      title: "Användarvillkor - Leija",
    },
  },
  {
    path: "/personuppgiftspolicy",
    name: "personuppgiftspolicy",
    component: PrivacyPolicy,
    meta: {
      title: "Personuppgiftspolicy - Leija",
    },
  },
  {
    path: "/unavailable",
    name: "Construction",
    component: ConstructionPage,
    meta: {
      title: "Vi är inte riktigt klara här än - Leija",
    },
  }, */
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "404 Page not found - Leija",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  window.scrollTo(0, 0);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
