<template>
  <div class="container">
    <div class="text-wrapper">
      <h1>We create cool software for companies and people.</h1>
      <a href="mailto:contact@leija.se">Contact us</a>
    </div>
    <div class="icon-scroll" :class="{ hideScroll: scrollPosition > 50 }"></div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "HeaderSection",
  setup() {
    const scrollPosition = ref(0);
    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    return {
      scrollPosition,
      updateScroll,
    };
  },
};
</script>

<style scoped>
@media screen and (min-width: 995px) {
  .container {
    color: var(--primary-white);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: Nunito;
    height: 100vh;
    width: 100%;
  }
  .text-wrapper {
    width: 50%;
  }

  h1 {
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 2rem;
  }

  a {
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: var(--primary-gray);
    background-color: var(--primary-green);
    max-width: 10rem;
    text-align: center;
    border: 3px solid var(--primary-green);
  }

  a:hover {
    color: var(--primary-green);
    background-color: var(--primary-gray);
  }
}
.icon-scroll,
.icon-scroll:before {
  position: absolute;
  left: 50%;
}
.icon-scroll {
  z-index: 1;
  width: 40px;
  height: 70px;
  margin-left: -20px;
  top: 88%;
  margin-top: -36px;
  box-shadow: inset 0 0 0 1px var(--primary-white);
  border-radius: 25px;
  transition: ease-in-out 0.5s;
}
.icon-scroll:before {
  content: "";
  width: 8px;
  height: 8px;
  background: var(--primary-white);
  margin-left: -4px;
  top: 8px;
  border-radius: 4px;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-name: scroll;
}
@keyframes scroll {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(46px);
  }
}
.hideScroll {
  opacity: 0;
}
@media screen and (max-width: 994px) {
  .container {
    color: var(--primary-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5%;
    height: 100vh;
    width: 100%;
  }
  a {
    font-size: 1rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: var(--primary-gray);
    background-color: var(--primary-green);
    max-width: 10rem;
    text-align: center;
    border: 3px solid var(--primary-green);
  }

  a:hover {
    color: var(--primary-green);
    background-color: var(--primary-gray);
  }
  .text-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  }
  h1 {
    margin-top: 0;
  }
  p {
    padding: 0;
    margin: 0;
    height: 10rem;
  }
}
</style>
