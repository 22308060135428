<template>
  <div class="landingWrapper">
    <NavBar
      class="Navbar"
      :class="
        ({ navbarHeight: scrollPosition > 50 && windowSize >= 995 },
        { scrolled: scrollPosition > 50 })
      "
    />
    <div class="content">
      <HeaderSection />
      <SaFunkarDet />
      <FooterSection />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import HeaderSection from "../components/HeaderSection";
import SaFunkarDet from "../components/SaFunkarDet.vue";
import FooterSection from "../components/FooterSection.vue";

import { ref, onMounted, onBeforeMount } from "vue";

export default {
  name: "LandingPage",
  components: {
    NavBar,
    HeaderSection,
    SaFunkarDet,
    FooterSection,
  },
  setup() {
    const windowSize = ref(null);
    const scrollPosition = ref(0);

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      scrollPosition,
      updateScroll,
      windowSize,
    };
  },
};
</script>

<style scoped>
.Navbar {
  position: fixed;
}
.scrolled {
  background-color: var(--primary-gray);
  transition: 0.3s;

  box-sizing: border-box;
}
.navbarHeight {
  height: 7vh;
}
@media screen and (max-width: 994px) {
  .landingWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    gap: 15%;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
}
</style>
