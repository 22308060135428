<template>
  <div class="wrapper">
    <img src="~@/assets/FooterWave.svg" alt="decorativvåg" class="wave" />
    <div class="content">
      <div class="box">
        <div class="group1">
          <router-link to="/"
            ><img src="~@/assets/TenconTL.svg" alt="logotyp" class="logotyp"
          /></router-link>
          <p class="tag-text" v-if="windowSize >= 995">
            Copyright © 2023 Leija Software AB All rights reserved. <br />
            Organization number: 559317-2801
          </p>
        </div>
        <div class="group2">
          <div class="contact">
            <!-- <p class="contact-text">
              Contact: <br />
              <a class="left" href="mailto:hej@leija.se">contact@leija.se</a>
            </p> -->
          </div>
          <div class="pages">
            <!-- <router-link to="/cookies"><p>Cookies</p></router-link>
            <router-link to="/anvandarvillkor"
              ><p>Användarvillkor</p></router-link
            >
            <router-link to="/personuppgiftspolicy"
              ><p>Personuppgiftspolicy</p></router-link
            > -->
            <p class="tag-text" v-if="windowSize < 995">
              Copyright © 2022 Leija Software AB All rights reserved. <br />
              Organization number: 559317-2801
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";

export default {
  name: "FooterSection",
  setup() {
    const showNavbar = ref(false);
    const windowSize = ref(null);

    function toggleNavbar() {
      showNavbar.value = !showNavbar.value;
    }

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      showNavbar,
      toggleNavbar,
      windowSize,
    };
  },
};
</script>

<style scoped>
@media screen and (min-width: 995px) {
  .wrapper,
  a {
    color: var(--primary-white);
    text-decoration: none;
  }
  .wave {
    min-width: 100%;
  }
  .content {
    display: flex;
    justify-content: center;
    background-color: var(--secondary-gray);
    width: 100%;
    height: 25vh;
    margin-top: -7rem;
  }
  .box {
    display: flex;
    justify-content: space-between;
    width: 80%;
  }
  .group1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .group2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-right: 5rem;
  }
  .pages {
    display: flex;
    align-items: space-evenly;
  }
  .contact {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: left;
    margin-bottom: 2rem;
  }
  .logotyp {
    width: 65%;
    margin-left: 5rem;
  }
  .tag-text {
    padding-left: 5rem;
    font-size: 0.75rem;
  }
  p {
    padding: 1rem;
  }
  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
    margin-top: 2rem;
  }
  p,
  a {
    font-size: 1.25rem;
  }
  a {
    padding-right: 1.45rem;
  }

  h1,
  h2,
  p:not(.contact-text),
  a {
    text-align: left;
  }
  p.contact-text {
    padding-bottom: 1rem;
    margin-bottom: 0;
  }

  a:hover,
  router-link:hover {
    text-decoration: underline 1px var(--primary-green);
  }
}
@media screen and (max-width: 994px) {
  .wrapper {
    margin-top: 8rem;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  .logotyp {
    margin-top: -20%;
    width: 50%;
    margin-bottom: 5rem;
  }
  .wave {
    width: 500%;
    overflow-x: hidden;
  }
  .content {
    width: 100%;
    color: var(--primary-white);
    background-color: var(--secondary-gray);
  }
  a {
    color: var(--primary-white);
    text-decoration: none;
  }
  .tag-text {
    left: 5px;
  }
  p,
  .contact {
    text-align: left;
    margin-left: 1rem;
  }
  p.contact-text {
    margin-left: 0;
  }
  .contact {
    margin-bottom: 5rem;
  }
  a:hover,
  router-link:hover {
    text-decoration: underline 1px var(--primary-green);
  }
}
</style>
