<template>
  <div class="sa-funkar-det-wrapper">
    <h1>Our brands and products</h1>
    <div class="bidding">
      <a href="https://getshipd.co" target="_blank" class="image-a">
        <img src="~@/assets/shipedBL.svg" alt="" class="image" />
      </a>
      <div class="text-wrapper">
        <h2>Shipd</h2>
        <p>
          Universal Clipboard for all your devices. <br />
          Check out Shipd at:
          <a href="https://getshipd.co" target="_blank">https://getshipd.co</a>
        </p>
      </div>
    </div>
    <div class="cta">
      <a class="cta-button" href="mailto:contact@leija.se">Contact Us</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "SaFunkarDet",
};
</script>

<style scoped>
a {
  color: var(--primary-white);
  text-decoration: underline;
  padding: 0;
  margin: 0;
}
@media screen and (min-width: 995px) {
  .sa-funkar-det-wrapper {
    color: var(--primary-white);
    margin-top: 10rem;
    display: flex;
    flex-direction: column;
  }
  .advert,
  .bidding,
  .signing,
  .work {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
    margin-top: 2rem;
  }
  .text-wrapper {
    width: 35%;
  }
  .image-a {
    width: 20%;
    background-color: #dff6ff;
    padding: 5rem;
    border-radius: 50%;
  }
  h1 {
    margin-bottom: 0rem;
    margin-left: 15%;
    text-align: left;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  p {
    font-size: 1rem;
  }
  h2,
  p {
    padding-left: 5rem;
    text-align: left;
  }
  a.cta-button {
    display: block;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin-bottom: 15rem;
    color: var(--primary-gray);
    background-color: var(--primary-green);
    width: 15%;
    text-align: center;
    border: 3px solid var(--primary-green);
    margin-left: 15%;
  }

  a.cta-button:hover {
    color: var(--primary-green);
    background-color: var(--primary-gray);
  }
}
@media screen and (max-width: 994px) {
  .sa-funkar-det-wrapper {
    color: var(--primary-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .advert {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
  .bidding {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
  }
  .signing {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
  .work {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
  }
  .text-wrapper {
    width: 90%;
  }
  .image-a {
    width: 20%;
    background-color: #dff6ff;
    padding: 5rem;
    border-radius: 50%;
  }
  h1 {
    font-size: 3rem;
    margin-bottom: 3rem;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  p {
    font-size: 1.25rem;
  }
  a.cta-button {
    display: block;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    color: var(--primary-gray);
    background-color: var(--primary-green);
    width: 70%;
    text-align: center;
    border: 3px solid var(--primary-green);
  }

  a.cta-button:hover {
    color: var(--primary-green);
    background-color: var(--primary-gray);
  }
  .cta {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
