<template>
  <link
    rel="stylesheet"
    href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
  />
  <router-view />
</template>

<script>
export default {
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

:root {
  --primary-gray: #4d4646;
  --secondary-gray: #5b5656;
  --hover-gray: #575353;
  --primary-white: #f5eaea;
  --primary-green: #7fcd91;
  --primary-red: #e96767;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  background-color: transparent;
}
body::-webkit-scrollbar-thumb {
  background-color: var(--secondary-gray);
}
html {
  scroll-behavior: smooth;
}
body {
  background: var(--primary-gray);
  margin: 0;
  font-family: "Nunito";
  z-index: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
}
#app {
  font-family: Nunito;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
