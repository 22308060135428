<template>
  <div class="wrapper" :class="{ showNavbar: showNavbar == true }">
    <div class="box" :class="{ navbarStyle: showNavbar == true }">
      <router-link to="/"
        ><img
          src="~@\assets\TenconTL.svg"
          alt="logotyp"
          class="logotyp"
          :class="{ logotypHeight: scrollPosition > 50 && windowSize >= 995 }"
      /></router-link>
      <button v-if="!showNavbar && windowSize < 995" @click="toggleNavbar">
        <i class="fa fa-bars icon" aria-hidden="true"></i>
      </button>
      <button v-if="showNavbar && windowSize < 995" @click="toggleNavbar">
        <i class="fa fa-times icon" aria-hidden="true"></i>
      </button>
      <div class="cta" v-if="showNavbar || windowSize >= 995">
        <a class="register" href="mailto:contact@leija.se">Contact us</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, onMounted } from "vue";

export default {
  name: "NavBar",
  setup() {
    const showNavbar = ref(false);
    const windowSize = ref(null);
    const scrollPosition = ref(0);

    function toggleNavbar() {
      showNavbar.value = !showNavbar.value;
    }

    function updateScroll() {
      scrollPosition.value = window.scrollY;
    }

    onMounted(() => {
      window.addEventListener("scroll", updateScroll);
    });

    onBeforeMount(() => {
      windowSize.value = window.innerWidth;
    }),
      window.addEventListener("resize", () => {
        windowSize.value = window.innerWidth;
      });

    return {
      showNavbar,
      toggleNavbar,
      windowSize,
      scrollPosition,
    };
  },
};
</script>

<style scoped>
@media screen and (min-width: 995px) {
  .wrapper {
    font-family: Nunito;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }

  .logotyp {
    height: 5vh;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-left: 5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 00.5rem;
  }

  .cta {
    margin-right: 5rem;
  }

  .cta a {
    text-decoration: none;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    margin: 1rem;
  }

  .login {
    background-color: var(--secondary-gray);
    color: var(--primary-white);
    border: 3px solid var(--secondary-gray);
  }

  .register {
    color: var(--primary-gray);
    background-color: var(--primary-green);
    border: 3px solid var(--primary-green);
  }

  .register:hover {
    color: var(--primary-green);
    background-color: var(--primary-gray);
  }

  .login:hover {
    background-color: var(--hover-gray);
    border: 3px solid var(--hover-gray);
  }
  .logotypHeight {
    height: 5vh;
    transition: 0.3s;
  }
}
@media screen and (max-width: 994px) {
  .wrapper {
    font-family: Nunito;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow-x: hidden;
  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
  }
  .logotyp {
    width: 200px;
    padding: 0.5rem 1rem 0.5rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 00.5rem;
  }
  a {
    text-decoration: none;
    color: var(--primary-white);
    font-size: 2rem;
  }
  .icon {
    color: var(--primary-white);
    font-size: 2.25rem;
    width: 20px;
    cursor: pointer;
  }
  .icon:hover {
    color: var(--secondary-gray);
  }
  button {
    background: transparent;
    border: none;
  }
  .cta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15%;
    height: 100%;
  }
  a:hover {
    color: var(--primary-green);
  }
  .showNavbar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 5%;
    background-color: var(--primary-gray);
    z-index: 500;
    position: absolute;
  }
  .navbarStyle {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5%;
  }
}
</style>
